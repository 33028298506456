<template>
  <!-- ======= Portfolio Section ======= -->

  <section id="portfolio" class="portfolio">
    <!-- <a href="#portfolio"></a> -->
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Portfolio</h2>
        <p>Check My Portfolio</p>
      </div>

      <div class="activity" >
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <!-- ========================= สไลด์พอร์ตฟอริโอ ============================= -->
          <div class="carousel-inner" >
            <div
              class="carousel-item"
              :class="{ active: index === 0 }"
              v-for="(activity, index) in activitys"
              :key="index"
            >
              <span v-if="activity.type == '2'">
                <div class="row" style="">
                  <div class="col-lg-3" style="">
                    <img :src="activity.image" class="img-fluid"/>
                  </div>

                  <div class="col-lg-9" >
                    <div class="" style="margin-top:5%; margin-right:8%; ">
                      <span class="p-3" style="width: 100%; ">
                        <h5>{{ activity.title }}</h5>

                        <p>{{ activity.content }}</p>
                      </span>
                      <a
                        target="_blank"
                        :href="activity.url"
                        class="btn btn-warning my-2"
                        >เว็บไซต์</a
                      >
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              class="carousel-control-prev-icon"
              aria-hidden="true"
              style="margin-left: -100px"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon"
              aria-hidden="true"
              style="margin-right: -100px"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <!-- =========================== ผลงาน ============================= -->
      <div class="row">
        <div
          type="button"
          v-for="post in posts.slice(0, 3)"
          :key="post.id"
          class="col-lg-4 col-md-6 d-flex align-items-stretch my-3"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            class="card icon-box"
            style="width: 100%"
            v-if="post.type == '1'"
            @click="clickModel(post.id)"
          >
            <img :src="post.image" :title="post.title" />
            <br />
            <a> {{ post.title }} </a>
            <div class="card-body">
              <p class="card-text">
                {{ post.content }}
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="d-flex justify-content-end">
          <a
            class="get-started-btn text-dark mt-3"
            href="portfolioP2.vue"
            target="_blank"
            >SEE ALL</a
          >
        </div> -->
        <div class="d-flex justify-content-end">
          <a
            role="button"
            class="get-started-btn text-dark mt-3"
            data-bs-toggle="modal"
            href="#exampleModalToggle"
            >SEE ALL</a
          >
        </div>
      </div>
    </div>

    <!-- ============================ See All ================================ -->
    <div class="d-flex justify-content-end" style="margin-right: 80px">
      <!-- <a
        role="button"
        class="get-started-btn text-dark mt-3"
        data-bs-toggle="modal"
        href="#exampleModalToggle"
        >SEE ALL</a
      > -->
      <div
        class="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel">
                All Portfolio
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4" id="result">
              <!-- =============================== Show all portfolio ================================= -->
              <div class="row">
                <!-- <button> -->
                <div
                  type="button"
                  v-for="post in posts"
                  :key="post.id"
                  class="col-lg-4 col-md-6 d-flex align-items-stretch my-3"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  @click="clickModel(post.id)"
                >
                  <div
                    class="card icon-box"
                    style="width: 100%"
                    v-if="post.type == '1'"
                  >
                    <img :src="post.image" :title="post.title" />
                    <br />
                    <a> {{ post.title }} </a>
                    <div class="card-body">
                      <p class="card-text">
                        {{ post.content }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </button>   -->
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-warning"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel2">
                {{ posts2.title }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-center">
              <!-- Id : {{ posts2.id }} <br /> -->
              {{ posts2.content }} <br /><br />
              <img :src="posts2.image" height="400" width="" alt="" />
              <br />
              <br />
              วันที่ : {{ posts2.date_at }}
            </div>

            <div class="modal-footer">
              <button
                class="btn btn-warning"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- test -->

    <!-- Modal test -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-full">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ posts2.title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">
            <!-- id: {{ posts2.id }} <br /> -->
            {{ posts2.content }} <br /><br />
            <img :src="posts2.image" height="400" alt="" />
            <br />
            <br />
            วันที่ : {{ posts2.date_at }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-warning"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Portfolio Section -->
</template>

<script>
import axios from "axios";
export default {
  name: "portfolio",
  data() {
    return {
      posts: [],
      activitys: [],
      posts2: [],
    };
  },
  methods: {
    clickModel(id) {
      axios
        .get(
          "http://dev.jigsawinnovation.com/jigsaw_backend/api/portfolio/" + id
        )
        .then((response) => {
          this.posts2 = response.data.portfolio;
          console.log(this.posts2);
        })
        .catch((error) => {
          console.log(error);
          // this.errored = true;
        });
    },
  },
  created() {
    axios
      .get("http://dev.jigsawinnovation.com/jigsaw_backend/api/portfolio")
      .then((Response) => {
        // console.log(Response.data);
        var a = Response.data;
        var interesting = a.filter(function(value) {
          return value.type == "2";
        });
        var interesting2 = a.filter(function(value) {
          return value.type == "1";
        });
        this.posts = interesting2;
        this.activitys = interesting;
        // console.log(interesting);
      })

      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {},
};
</script>

<style></style>
