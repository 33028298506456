<template>
  <!-- ======= Team Section ======= -->

  <section id="team" class="team">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Team</h2>
        <p>Check our Team</p>
      </div>

      <div class="row text-center">
        <div class="col-lg-3 col-md-6 d-flex align-items-stretch"
              v-for="post in posts" :key="post.id">
          <div class="member" data-aos="fade-up" data-aos-delay="100">
            <div class="member-img">
              <img
                :src="post.image"
                class="img-fluid"
                alt=""
              />
              <div class="social">
                <!-- <a href=""><i class="fab fa-facebook"></i></a>
                <a href=""><i class="fab fa-instagram"></i></a>
                <a href=""><i class="fab fa-line"></i></a> -->
              </div>
            </div>
            <div class="member-info">
              <h4> {{post.firstname}} &nbsp;{{post.lastname}} </h4>
              <b><p class="italic"> {{post.nickname}} </p></b>
              <!-- <span><h3>GON</h3></span> -->
            </div>
          </div>
        </div>

        
        </div>
      </div>
   
  </section>
  <!-- End Team Section -->
</template>

<script>
import axios from "axios";
export default {
  name: "portfolio",
  data() {
    return {
      posts: [],
    };
  },
  methods: {},
  created() {
    axios
      .get("http://dev.jigsawinnovation.com/jigsaw_backend/api/team")
      .then((Response) => {
        this.posts = Response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style></style>
