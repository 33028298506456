<template>
  <!-- OUR CLIENT  -->
  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">
    <div class="container" data-aos="zoom-in">
      <div class="section-title">
        <h2>OUR CLIENT</h2>
        <p>Check our CLIENT</p>
      </div>

      <div class="entire-content">
        <div class="content-carrousel">
          <figure v-for="post in posts" :key="post.id">
            <img :src="post.image" style="width:100%; height:100%"/>
            <!-- <img :src="post.image" :title="post.title" /> -->
          </figure>
        </div>
      </div>
    </div>
  </section>
  <!-- End Clients Section -->
</template>

<script>
import axios from "axios";
export default {
  name: "clients",
  data() {
    return {
      posts: [],
    };
  },
  methods: {},
  created() {
    axios
      .get("http://dev.jigsawinnovation.com/jigsaw_backend/api/cilent")
      .then((Response) => {
        this.posts = Response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style></style>
