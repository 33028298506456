<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="text-center">
          <div v-for="post in posts" :key="post.id"
                class="footer-info">
            <h3>Jigsaw Innovation<span>.</span></h3>
            <p>
              <strong>Phone:</strong> {{post.telno}} <br />
              <strong>Email:</strong> {{post.email}} <br />
            </p>
            <div class="social-links mt-3">
              <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> -->
              <a
                href="https://www.facebook.com/JigsawInnovation"
                target="_bank"
                class="facebook"
                ><i class="bx bxl-facebook"></i
              ></a>
              <!-- <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
              <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> -->
            </div>
          </div>
        </div>

        <!-- <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3 footer-ml">
          <div class="col-lg col-md footer-links">
            <h4>PRODUCTS</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Domains</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Websites</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Online Stores</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Mobile Apps</a>
              </li>
            </ul>
          </div>

          <div class="col-lg col-md footer-links">
            <h4>FOR PARTNERS</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Online Store</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Photographers</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Bloggers</a>
              </li>
            </ul>
          </div>

          <div class="col-lg col-md footer-links">
            <h4>COMPANY</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">About</a></li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Careers</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Press & Media</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Terms of Services</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Privecy Policy</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Contact Us</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Timelime</a>
              </li>
            </ul>
          </div>

          <div class="col-lg col-md footer-links">
            <h4>COMMUNITY</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">CSR: Corporate Social Responsibility</a>
              </li>
            </ul>
          </div>

          <div class="col-lg col-md footer-links">
            <h4>FOLLOW</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a
                  href="https://www.facebook.com/JigsawInnovation"
                  target="_bank"
                  >Facebook</a
                >
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        <p>© COPYRIGHT 2021 Jigsaw Innovation Co., Ltd.</p>
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<script>
import axios from "axios";
export default {
  name: "portfolio",
  data() {
    return {
      posts: [],
    };
  },
  methods: {},
  created() {
    axios
      .get("http://dev.jigsawinnovation.com/jigsaw_backend/api/contact")
      .then((Response) => {
        this.posts = Response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style></style>
