<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Contact</h2>
        <p>Contact Us</p>
      </div>

      <div class="row mt-5">
        <div class="col-lg-4">
          <div v-for="post in posts" :key="post.id"
                class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p> {{post.address1}} </p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p> {{post.email}} </p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Call:</h4>
              <p> {{post.telno}} </p>
            </div>
          </div>
        </div>

        <div class="col-lg-8 mt-5 mt-lg-0">
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d944.1927935241212!2d98.9624191292256!3d18.808348368695555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3a5e795c8c23%3A0x5febef9f4f9c5302!2z4Lir4Lit4Lie4Lix4LiB4Lig4Li54Lif4LmJ4Liy!5e0!3m2!1sth!2sth!4v1620639121064!5m2!1sth!2sth"
              width="100%"
              height="280px"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</template>

<script>
import axios from "axios";
export default {
  name: "portfolio",
  data() {
    return {
      posts: [],
    };
  },
  methods: {},
  created() {
    axios
      .get("http://dev.jigsawinnovation.com/jigsaw_backend/api/contact")
      .then((Response) => {
        this.posts = Response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style></style>
