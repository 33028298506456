<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <HelloWorld msg="Welcome to Your Vue.js App"/>
  <div> <home/> </div>
  <div> <about/> </div>
  <div> <portfolio/> </div>
  <div> <teams/> </div>
  <div> <clients/> </div>
  <div> <contact/> </div>
  <div> <footers/> </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import about from './components/about.vue'
import clients from './components/clients.vue'
import contact from './components/contact.vue'
import footers from './components/footers.vue'
import home from './components/home.vue'
import portfolio from './components/portfolio.vue'
import teams from './components/teams.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    about,
    clients,
    contact,
    footers,
    home,
    portfolio,
    teams,
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
