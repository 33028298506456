import { createApp } from 'vue'
import App from './App.vue'
import Vue from 'vue'


global.Vue = Vue
// axios
// import VueAxios from "vue-axios";
// import axios from "axios";
// import Vue from "vue";

// Vue.use(VueAxios, axios);

createApp(App).mount('#app')


