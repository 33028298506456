<template>
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>ABOUT</h2>
        <p>Check ABOUT</p>
      </div>

      <div class="row">
        <div
          class="col-lg-12 pt-4 pt-lg-0 content"
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <p class="text-center">
            จัดตั้งขึ้นบนพื้นฐานความเชื่อเดียวกันของกลุ่มคนที่เชื่อว่า
            “ในเมื่อเทคโนโลยีพามนุษย์โลกไปถึงดวงจันทร์ได้
            นวัตกรรมก็ย่อมสามารถถอดรหัสความฝัน
            และแก้ไขปัญหาที่เกิดขึ้นได้เช่นกัน” บริษัท จิ๊กซอว์ อินโนเวชั่น
            จำกัด จึงได้ก่อตัวขึ้น โดยมีวัตถุประสงค์และเป้าหมายเดียวกันคือการ
            ศึกษา ออกแบบ
            และพัฒนานวัตกรรมและเทคโนโลยีที่ตอบสนองความต้องการของมนุษย์ได้จริง
          </p>
        </div>
      </div>

      <div class="row mt-5 text-center">
        <div class="col-lg-3">
          <div class="icon-box">
            <i class="bi bi-display"></i>
            <h3><a href="">SOFTWARE</a></h3>
            <p>
              ประกอบกิจการ ออกแบบ พัฒนา หรือจัดหา
              ระบบเทคโนโลยีสารสนเทศเพื่อสนับสนุนภารกิจหรือธุรกิจ ให้แก่ บุคคล
              คณะบุคคล นิติบุคคล ส่วนราชการ และองค์กรของรัฐ
              ทั้งภายในประเทศและต่างประเทศ
            </p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mt-lg-0">
          <div class="icon-box">
            <i class="bi bi-cpu"></i>
            <h3><a href="">HARDWARE</a></h3>
            <p>
              ประกอบกิจการค้า จำหน่าย หรือให้บริการ ระบบงานผลิตภัณฑ์ซอฟต์แวร์
              ฮาร์ดแวร์ รวมทั้งอุปกรณ์ที่เกี่ยวข้องด้านเทคโนโลยี
              ทั้งภายในประเทศและต่างประเทศ
            </p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mt-lg-0">
          <div class="icon-box">
            <i class="bi bi-crop"></i>
            <h3><a href="">GRAPHIC</a></h3>
            <p>
              กระบวนการสร้างสรรค์ หรือการออกแบบเพื่อใช้ในการสื่อสาร
              อันเกิดจากการผสมผสานของ แนวความคิด ศิลปะ เทคโนโลยี หรือข้อมูล
              ผ่านทางสื่อในรูปแบบต่างๆ
            </p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mt-lg-0">
          <div class="icon-box">
            <i class="bi bi-three-dots"></i>
            <h3><a href="">OTHER</a></h3>
            <p>
              ศึกษา วิจัย และพัฒนา นวัตกรรม ผลิตภัณฑ์ซอฟต์แวร์ ฮาร์ดแวร์
              รวมทั้งอุปกรณ์ที่เกี่ยวข้องด้านเทคโนโลยี
              เพื่อยกระดับกระบวนการบริหารงานบริษัท ผลิตภัณฑ์
              และสร้างโอกาสทางธุรกิจใหม่ของกลุ่มเป้าหมายทั้งในปัจจุบันและอนาคต
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->
</template>

<script>
export default {};
</script>

<style></style>
